enum Steps {
    STEP_ONE = 1, // get initial shipping data
    STEP_TWO = 2, // pick shipping method
    STEP_THREE = 3, // post the order and show printable confirmation
}

const currentStep = ref(Steps.STEP_ONE);

export default function () {
    const router = useRouter();

    switch (router.currentRoute.value.path) {
        case '/shipping':
            currentStep.value = Steps.STEP_TWO;
            break;
        case '/confirm':
            currentStep.value = Steps.STEP_THREE;
            break;
        default:
            currentStep.value = Steps.STEP_ONE;
            break;
    }

    // listen for changes to the current step and update the URL
    watch(currentStep, (step) => {
        switch (step) {
            case Steps.STEP_ONE:
                router.push('/');
                break;
            case Steps.STEP_TWO:
                router.push('/shipping');
                break;
            case Steps.STEP_THREE:
                router.push('/confirm');
                break;
        }
    });

    return {
        Steps,
        currentStep: readonly(currentStep),
        nextStep: () => (currentStep.value += 1),
        previousStep: () => (currentStep.value -= 1),
        setStep: (step: Steps) => (currentStep.value = step),
    };
}
